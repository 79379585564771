import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import Base from '@webLayouts/Base/Base';
import { PagePrefabHomesQuery } from '@generated/GraphqlTypes';
import Designs from '@webPages/ModularHomes/Designs';
import SeoSections from '@webPages/ModularHomes/Sections/SeoSections';
import QAllDesigns from '@staticQueries/AllDesigns';
import PageHeading from '@webMolecules/PageHeading/PageHeading';

const IndexPage: React.FC<PageProps<PagePrefabHomesQuery>> = ({ data }) => {
  const seoTitle = data?.prefabHomes?.frontmatter?.seoTitle || '';
  const seoDescription = data?.prefabHomes?.frontmatter?.seoDescription || '';
  const title = data?.prefabHomes?.frontmatter?.title || '';
  const copy: { title: string; body: string }[] = (
    data?.prefabHomes?.frontmatter?.copy || []
  ).map(a => ({
    title: a?.title || '',
    body: a?.body || '',
    videoId: a?.videoId,
    videoCaption: a?.videoCaption,
  }));
  const faqs = (data?.prefabHomes?.frontmatter?.faqs || []).map(a => ({
    title: a?.title || '',
    body: a?.body || '',
  }));
  const faqTitle = data?.prefabHomes?.frontmatter?.faqTitle || '';
  const faqBody = data?.prefabHomes?.frontmatter?.faqBody || '';

  return (
    <Base theme="light" title={seoTitle} description={seoDescription}>
      <PageHeading title={title} />

      <SeoSections
        noTopPadding
        copy={copy}
        faqTitle={faqTitle}
        faqBody={faqBody}
        faqs={faqs}
      />
    </Base>
  );
};

export const query = graphql`
  query PagePrefabHomes {
    prefabHomes: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "prefab-homes" } }
    ) {
      frontmatter {
        title

        seoTitle
        seoDescription

        copy {
          title
          body
          videoId
          videoCaption
        }

        faqTitle
        faqBody
        faqs {
          title
          body
        }
      }
    }
  }
`;

export default IndexPage;
