import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import YoutubeEmbed from '@webAtoms/YoutubeEmbed/YoutubeEmbed';
import Accordion from '@webMolecules/Accordion/Accordion';
import { render } from '@helpers/markdown';
import * as styles from './seo-sections.module.scss';

interface DesignsCopy {
  title: string;
  body: string;
  videoId?: string;
  videoCaption?: string;
}

interface Faq {
  title: string;
  body: string;
}

export interface SeoSections {
  faqTitle: string;
  faqBody: string;
  faqs: Faq[];
  copy: DesignsCopy[];
  noTopPadding?: boolean;
}

const SeoSections: React.FC<SeoSections> = ({
  copy,
  faqTitle,
  faqBody,
  faqs,
  noTopPadding,
}) => {
  return (
    <Box
      className={[styles.seo, noTopPadding ? styles.noTopPadding : ''].join(
        ' '
      )}
    >
      <Container>
        <Box className={styles.seoGrid}>
          <Box className={styles.seoCopy}>
            {copy.map((copy, index) => (
              <Box key={index} className={styles.seoCopyBlock}>
                {copy.title && (
                  <h4 className={styles.seoCopyBlockHeading}>{copy.title}</h4>
                )}

                <Box
                  className={styles.seoCopyBlockBody}
                  htmlContent={render(copy.body)}
                />

                {copy.videoId && (
                  <Box className={styles.seoCopyBlockVideo}>
                    <YoutubeEmbed
                      fluid
                      playerRatio="16:9"
                      videoId={copy.videoId}
                    />
                    {copy.videoCaption && (
                      <Box className={styles.seoCopyBlockVideoCaption}>
                        {copy.videoCaption}
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            ))}
          </Box>

          <Box className={styles.seoFaq}>
            <Box className={styles.seoFaqInner}>
              <h2>{faqTitle}</h2>
              <Box htmlContent={faqBody} />

              <Box className={styles.seoFaqs}>
                {faqs.map(part => (
                  <Accordion key={part.title} title={part.title}>
                    <Box
                      className={styles.seoFaqsBody}
                      htmlContent={render(part.body)}
                    />
                  </Accordion>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SeoSections;
