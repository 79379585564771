// extracted by mini-css-extract-plugin
export var noTopPadding = "seo-sections-module--noTopPadding--8nvdI";
export var seo = "seo-sections-module--seo--KT38g";
export var seoBody = "seo-sections-module--seoBody--drlAV";
export var seoCopy = "seo-sections-module--seoCopy--N1J43";
export var seoCopyBlock = "seo-sections-module--seoCopyBlock--Pqryo";
export var seoCopyBlockBody = "seo-sections-module--seoCopyBlockBody--sSqia";
export var seoCopyBlockHeading = "seo-sections-module--seoCopyBlockHeading--VljcZ";
export var seoCopyBlockVideo = "seo-sections-module--seoCopyBlockVideo--xVref";
export var seoCopyBlockVideoCaption = "seo-sections-module--seoCopyBlockVideoCaption--rc3FU";
export var seoFaq = "seo-sections-module--seoFaq--ohKCk";
export var seoFaqBody = "seo-sections-module--seoFaqBody--q-gxM";
export var seoFaqInner = "seo-sections-module--seoFaqInner--pwWw6";
export var seoFaqs = "seo-sections-module--seoFaqs--XCgqm";
export var seoGrid = "seo-sections-module--seoGrid--Bc6yP";